import React, { useState, useEffect } from "react";
import "../styles/styles.css";
import { db } from "../firebase";
import {
  getDocs,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Modal,
  Box,
  TextField,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AnuncioDetailModal from "../components/anuncios/AnuncioDetailModal";
import { viewsAdById } from "../utils/functions";

export default function AnunciosScreen() {
  const [openDetail, setOpenDetail] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [selectedAnuncio, setSelectedAnuncio] = useState(null);
  const [comment, setComment] = useState("");
  const [anuncios, setAnuncios] = useState([]);

  const fetchAnuncios = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "anuncios"));
      const bannerList = await Promise.all(
        querySnapshot.docs.map(async (doc) => {
          const data = doc.data();
          const views = await viewsAdById(doc.id); // Obtener visualizaciones
          return { id: doc.id, ...data, views };
        })
      );
      setAnuncios(bannerList);
    } catch (error) {
      console.error("Error fetching banners: ", error);
    }
  };

  useEffect(() => {
    fetchAnuncios();
  }, []);

  const handleOpenDetail = (anuncio) => {
    setSelectedAnuncio(anuncio);
    setOpenDetail(true);
  };
  const handleCloseDetail = () => {
    setSelectedAnuncio(null);
    setOpenDetail(false);
  };

  const handleOpenApprovalModal = (anuncio) => {
    setSelectedAnuncio(anuncio);
    setComment("");
    setOpenApprovalModal(true);
  };

  const handleCloseApprovalModal = () => {
    setSelectedAnuncio(null);
    setComment("");
    setOpenApprovalModal(false);
  };

  const handleApproval = async (status) => {
    if (!selectedAnuncio) return;

    const idUser = selectedAnuncio.userId; // Asegúrate de que esto es correcto (selectedAnuncio debe tener userId)

    try {
      // Preparar los datos a actualizar en Firebase
      const updateData = { status };
      if (status === 2) updateData.comentario = comment; // Si es rechazado, agregar el comentario

      // Actualizar el estado en Firebase
      await updateDoc(doc(db, "anuncios", selectedAnuncio.id), updateData);
      fetchAnuncios();

      // Enviar la notificación al backend
      const motivo = status === 2 ? 10 : 9; // Si el estado es rechazado, enviamos el comentario, sino el motivo predefinido (9)

      const response = await fetch(
        "https://www.elpunto.com.ec/notpush/send_notification.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idUser, // El ID del usuario que está realizando la acción
            motivo, // El motivo (comentario o número predefinido)
          }),
        }
      );

      if (response.ok) {
        console.log("Notificación enviada con éxito");
      } else {
        console.error("Error al enviar la notificación");
      }

      // Mostrar el mensaje de éxito
      toast.success(
        `Anuncio ${status === 1 ? "aprobado" : "rechazado"} con éxito!`
      );
      handleCloseApprovalModal();
    } catch (error) {
      console.error("Error updating status: ", error);
      toast.error("Error al actualizar el estado!");
    }
  };

  return (
    <>
      <h2 className="titlePageInt">Anuncios</h2>

      <div
        style={{
          display: "flex",
          padding: 20,
          gap: 10,
          flexDirection: "column",
        }}
      >
        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHeaderCell">
                  <b>Título</b>
                </TableCell>
                <TableCell className="tableHeaderCell">
                  <b>Visualizaciones</b>
                </TableCell>
                <TableCell className="tableHeaderCell">
                  <b>Estado</b>
                </TableCell>
                <TableCell className="tableHeaderCell" colSpan={3}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {anuncios.map((anuncio) => (
                <TableRow key={anuncio.id}>
                  <TableCell>{anuncio.title}</TableCell>
                  <TableCell>{anuncio.views}</TableCell>
                  <TableCell>
                    <p
                      style={{
                        color:
                          anuncio.status === 0
                            ? "orange"
                            : anuncio.status === 1
                            ? "green"
                            : "red",
                      }}
                    >
                      {anuncio.status === 0
                        ? "Por aprobar"
                        : anuncio.status === 1
                        ? "Aprobado"
                        : "No aprobado"}
                    </p>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="view"
                      color="primary"
                      onClick={() => handleOpenDetail(anuncio)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    {anuncio.status === 0 && (
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() => handleOpenApprovalModal(anuncio)}
                        style={{ fontSize: 12, textTransform: "Capitalize" }}
                      >
                        Aprobar / Rechazar
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {selectedAnuncio && (
          <AnuncioDetailModal
            open={openDetail}
            handleClose={handleCloseDetail}
            anuncio={selectedAnuncio}
            fetchAnuncios={fetchAnuncios}
          />
        )}

        {/* Modal de aprobación o rechazo */}
        <Modal
          open={openApprovalModal}
          onClose={handleCloseApprovalModal}
          aria-labelledby="approval-modal-title"
          aria-describedby="approval-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <h2 id="approval-modal-title">Aprobar o Rechazar Anuncio</h2>
            <p>
              ¿Quieres aprobar o rechazar el anuncio{" "}
              <b>{selectedAnuncio?.title}</b>?
            </p>

            {selectedAnuncio?.status === 0 && (
              <>
                <TextField
                  label="Motivo de rechazo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  multiline
                  rows={3}
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={selectedAnuncio?.status !== 0}
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 20,
                  }}
                >
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleApproval(2)}
                    disabled={comment.trim() === ""}
                  >
                    Rechazar
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleApproval(1)}
                  >
                    Aprobar
                  </Button>
                </div>
              </>
            )}
          </Box>
        </Modal>
      </div>
    </>
  );
}
