import React from "react";
import { Link } from "react-router-dom";
import { Drawer, List, ListItem, ListItemText, Button } from "@mui/material";
import styled from "@emotion/styled";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import InventoryIcon from "@mui/icons-material/Inventory";
import MenuIcon from "@mui/icons-material/Menu";
import LabelIcon from "@mui/icons-material/Label";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import WarningIcon from "@mui/icons-material/Warning";
import logo from "./assets/logo.png";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import FeedIcon from "@mui/icons-material/Feed";
const DrawerPaper = styled.div`
  width: 250px;
`;

const CustomListItemText = styled(ListItemText)`
  .MuiListItemText-primary {
    font-size: 13px;
    font-family: "Poppins";
    color: #333;
    margin-left: 10px;
  }
`;

const TemporaryDrawer = ({ isLoggedIn, handleLogout }) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const listItems = [
    { text: "Home", icon: <HomeIcon htmlColor="#555" />, link: "/" },
    // { text: "About", icon: <InfoIcon htmlColor="#555" />, link: "/about" },
    {
      text: "Usuarios",
      icon: <PeopleIcon htmlColor="#555" />,
      link: "/UsersScreen",
    },
    {
      text: "Banners",
      icon: <FeedIcon htmlColor="#555" />,
      link: "/BannersScreen",
    },

    {
      text: "Anuncio",
      icon: <NewspaperIcon htmlColor="#555" />,
      link: "/AnunciosScreen",
    },
    {
      text: "Productos",
      icon: <InventoryIcon htmlColor="#555" />,
      link: "/ProductsScreen",
    },
    {
      text: "Categorias",
      icon: <LabelIcon htmlColor="#555" />,
      link: "/CategorysScreen",
    },
    {
      text: "Palabras filtradas",
      icon: <WarningIcon htmlColor="#555" />,
      link: "/FilterWordsScreen",
    },
    {
      text: "Preguntas frecuentes",
      icon: <QuestionAnswerIcon htmlColor="#555" />,
      link: "/Questions",
    },
  ];

  return (
    <div>
      <Button
        style={{
          position: "absolute",
          zIndex: 1,
          marginTop: 13,
        }}
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </Button>

      <Drawer open={open} onClose={toggleDrawer(false)}>
        <img
          src={logo}
          alt="logo"
          style={{ width: 100, height: "auto", margin: "15px auto" }}
        />
        <DrawerPaper>
          <List>
            {listItems.map((item) => (
              <ListItem
                button
                key={item.text}
                component={Link}
                to={item.link}
                onClick={toggleDrawer(false)}
              >
                {item.icon}
                <CustomListItemText primary={item.text} />
              </ListItem>
            ))}
            {isLoggedIn && (
              <ListItem button onClick={handleLogout}>
                <LogoutIcon htmlColor="#555" />
                <CustomListItemText primary="Cerrar sesión" />
              </ListItem>
            )}
          </List>
        </DrawerPaper>
      </Drawer>
    </div>
  );
};

export default TemporaryDrawer;
