import { db } from "../firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  getDocs,
  where,
} from "firebase/firestore";

// Función para obtener nombres de categoría y subcategoría
export const getCategoryAndSubcategory = async (
  categoriaId,
  subcategoriaId
) => {
  try {
    // Verificar existencia de categoriaId y subcategoriaId
    if (!categoriaId || !subcategoriaId) {
      console.error("CategoriaId or SubcategoriaId is missing.");
      return { categoriaNombre: "", subcategoriaNombre: "" };
    }

    const categoriaDoc = doc(db, "categorias", categoriaId);
    const categoriaSnap = await getDoc(categoriaDoc);

    if (!categoriaSnap.exists()) {
      console.error(`Categoría con ID ${categoriaId} no encontrada.`);
      return { categoriaNombre: "", subcategoriaNombre: "" };
    }

    const categoriaData = categoriaSnap.data();
    const categoriaNombre = categoriaData.nombre || "";

    const subcategoriaCollection = collection(categoriaDoc, "subcategorias");
    const subcategoriaDoc = doc(subcategoriaCollection, subcategoriaId);
    const subcategoriaSnap = await getDoc(subcategoriaDoc);

    if (!subcategoriaSnap.exists()) {
      console.error(`Subcategoría con ID ${subcategoriaId} no encontrada.`);
    }

    const subcategoriaNombre = subcategoriaSnap.exists()
      ? subcategoriaSnap.data().nombre
      : "";

    return { categoriaNombre, subcategoriaNombre };
  } catch (error) {
    console.error("Error fetching category and subcategory:", error);
    return { categoriaNombre: "", subcategoriaNombre: "" }; // Devuelve valores por defecto en caso de error
  }
};

export const fetchUserInfo = async (userId) => {
  try {
    const usersCollectionRef = collection(db, "users");
    const q = query(usersCollectionRef, where("uid", "==", userId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn(`No user found with ID ${userId}`);
      return null;
    }

    let userInfo = null;
    querySnapshot.forEach((doc) => {
      // Obtener datos del documento
      const data = doc.data();

      // Convertir Timestamp a fecha legible
      const createdAt = data.createdAt.toDate().toLocaleDateString();

      // Establecer información del usuario
      userInfo = { ...data, createdAt };
    });

    return userInfo;
  } catch (error) {
    console.error("Error fetching user info:", error);
    return null;
  }
};

export const fetchUserInfoByIdWithDates = async (userId) => {
  try {
    // Obtener la colección de usuarios y buscar el documento por userId
    const usersCollectionRef = collection(db, "users");
    const q = query(usersCollectionRef, where("uid", "==", userId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      console.warn(`No user found with ID ${userId}`);
      return null;
    }

    let userInfo = null;
    querySnapshot.forEach((doc) => {
      // Obtener datos del documento
      const data = doc.data();
      // Convertir Timestamp a fecha legible
      const createdAt = data.createdAt.toDate().toLocaleDateString();
      // Establecer información del usuario
      userInfo = { ...data, createdAt };
    });

    // Consulta para contar los productos del usuario
    const productosCollectionRef = collection(db, "productos");
    const productosQuery = query(
      productosCollectionRef,
      where("userId", "==", userId)
    );
    const productosSnapshot = await getDocs(productosQuery);
    const productosCount = productosSnapshot.size;

    // Consulta para contar las compras del usuario (cuando el userId es el buyerId)
    const comprasCollectionRef = collection(db, "ventas");
    const comprasQuery = query(
      comprasCollectionRef,
      where("buyerId", "==", userId)
    );
    const comprasSnapshot = await getDocs(comprasQuery);
    const comprasCount = comprasSnapshot.size;

    // Consulta para contar las ventas del usuario (cuando el userId es el sellerId)
    const ventasQuery = query(
      comprasCollectionRef,
      where("sellerId", "==", userId)
    );
    const ventasSnapshot = await getDocs(ventasQuery);
    const ventasCount = ventasSnapshot.size;

    // Retornar toda la información del usuario junto con las estadísticas
    return {
      ...userInfo,
      productosCount,
      comprasCount,
      ventasCount,
    };
  } catch (error) {
    console.error("Error fetching user info with dates:", error);
    return null;
  }
};

export const getStatusText = (status) => {
  switch (status) {
    case 0:
      return <span style={{ color: "orange" }}>Esperando Aprobación</span>;
    case 1:
      return <span style={{ color: "green" }}>Aprobado</span>;
    case 2:
      return <span style={{ color: "green" }}>Aprobado | Inactivo</span>;
    case 3:
      return <span style={{ color: "red" }}>No aprobado</span>;
    default:
      return <span style={{ color: "gray" }}>Desconocido</span>;
  }
};

export const getProductCountByCategory = async (categoryId) => {
  try {
    if (!categoryId) {
      console.error("CategoryId is missing.");
      return 0;
    }

    const productsCollection = collection(db, "productos");
    const q = query(productsCollection, where("categoria", "==", categoryId));
    const querySnapshot = await getDocs(q);

    return querySnapshot.size; // Retorna el conteo de documentos
  } catch (error) {
    console.error("Error fetching product count:", error);
    return 0; // En caso de error, retorna 0
  }
};

export const getProductCountBySubcategory = async (subcategoryId) => {
  try {
    if (!subcategoryId) {
      console.error("subCategoryId is missing.");
      return 0;
    }

    const productsCollection = collection(db, "productos");
    const q = query(
      productsCollection,
      where("subcategoria", "==", subcategoryId)
    );
    const querySnapshot = await getDocs(q);

    return querySnapshot.size; // Retorna el conteo de documentos
  } catch (error) {
    console.error("Error fetching product count:", error);
    return 0; // En caso de error, retorna 0
  }
};

export const getCategoryName = async (categoryId) => {
  try {
    const categoryRef = doc(db, "categorias", categoryId);
    const categorySnap = await getDoc(categoryRef);

    if (categorySnap.exists()) {
      return categorySnap.data().nombre;
    } else {
      console.log("No such category!");
      return "Categoría no encontrada";
    }
  } catch (error) {
    console.error("Error getting category name: ", error);
    return "Error";
  }
};

export const getSubcategoryName = async (categoryId, subcategoryId) => {
  try {
    const subcategoryRef = doc(
      db,
      "categorias",
      categoryId,
      "subcategorias",
      subcategoryId
    );
    const subcategorySnap = await getDoc(subcategoryRef);

    if (subcategorySnap.exists()) {
      return subcategorySnap.data().nombre;
    } else {
      console.log("No such subcategory!");
      return "Subcategoría no encontrada";
    }
  } catch (error) {
    console.error("Error getting subcategory name: ", error);
    return "Error";
  }
};

export const viewsAdById = async (adId) => {
  console.log("viewsAdById -> adId recibido:", adId);

  try {
    if (!adId) {
      console.error("Error: adId está vacío o es undefined.");
      return 0;
    }

    const adIdString = String(adId).trim();
    const adsCollection = collection(db, "view_ads");
    const q = query(adsCollection, where("adId", "==", adIdString));
    const querySnapshot = await getDocs(q);
    const documentsArray = [];
    let documentsCount = 0;

    querySnapshot.forEach((doc) => {
      documentsArray.push(doc.data());
      documentsCount++;
    });

    const size = querySnapshot.size;
    return size;
  } catch (error) {
    console.error("Error al obtener el conteo de vistas:", error);
    return 0; // En caso de error, retorna 0
  }
};
