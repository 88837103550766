import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppBar, Toolbar, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Home from "./screens/Home";
// import About from "./screens/About";
import { User } from "./screens/User";
import Login from "./Login";
import ProductsScreen from "./screens/ProductsScreen";
import AnunciosScreen from "./screens/AnunciosScreen";

import CategorysScreen from "./screens/CategorysScreen";
import BannersPublicidadScreen from "./screens/BannersPublicidadScreen";
import FilterWordsScreen from "./screens/FilterWordsScreen";
import QuestionsScreen from "./screens/QuestionsScreen";
import ProtectedRoute from "./ProtectedRoute";
import { logout } from "./firebase";
import UsersScreen from "./screens/UsersScreen";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Theme";
import TemporaryDrawer from "./TemporaryDrawer";
import { NumStatusCeroProductAppBar } from "./components/NumStatusCeroProductAppBar";
import { ProductStatusProvider } from "./ProductStatusContext"; // Importa el contexto
import { ToastContainer } from "react-toastify"; // Importa ToastContainer
import "react-toastify/dist/ReactToastify.css"; // Importa el CSS de Toastify

const Content = styled.div`
  // margin-left: 240px;
  // padding: 16px;
`;

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const session = localStorage.getItem("isLoggedIn");
    if (session === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };

  const handleLogout = () => {
    logout();
    setIsLoggedIn(false);
  };

  return (
    <Router>
      <ThemeProvider theme={theme}>
        <ProductStatusProvider>
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              alignContent: "flex-start",
            }}
          >
            {isLoggedIn && (
              <TemporaryDrawer
                isLoggedIn={isLoggedIn}
                handleLogout={handleLogout}
              />
            )}
            <Content
              style={{
                flex: 1,
                padding: 0,
                width: "100%",
              }}
            >
              {isLoggedIn && (
                <AppBar
                  position="static"
                  style={{ background: "#000", paddingLeft: 30 }}
                >
                  <Toolbar>
                    <Typography variant="h6">Admin Panel</Typography>
                    <div style={{ marginLeft: "auto", flexDirection: "row" }}>
                      <NumStatusCeroProductAppBar />
                    </div>
                  </Toolbar>
                </AppBar>
              )}
              <Routes>
                <Route
                  path="/"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/Questions"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <QuestionsScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/UsersScreen"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <UsersScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/ProductsScreen"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <ProductsScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/CategorysScreen"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <CategorysScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/user/:userId"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <User />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/AnunciosScreen/"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <AnunciosScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/BannersScreen/"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <BannersPublicidadScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
                <Route
                  path="/FilterWordsScreen/"
                  element={
                    isLoggedIn ? (
                      <ProtectedRoute>
                        <FilterWordsScreen />
                      </ProtectedRoute>
                    ) : (
                      <Login onLogin={handleLogin} />
                    )
                  }
                />
              </Routes>
              <ToastContainer />
            </Content>
          </div>
        </ProductStatusProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
