import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  doc,
  query,
  where,
  deleteDoc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import Table from "@mui/material/Table";
import { TableBody, Typography } from "@mui/material/";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import Pagination from "@mui/material/Pagination";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { User } from "./User";
import { EditUserModal } from "../components/EditUserModal";
import { CSVLink } from "react-csv";
import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";

function UsersScreen() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filteredUsersDate, setFilteredUsersDate] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [page, setPage] = useState(1);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [startDate, setStartDate] = useState(""); // Estado para fecha de inicio
  const [endDate, setEndDate] = useState(""); // Estado para fecha de fin
  const itemsPerPage = 10;

  const [openDateModal, setOpenDateModal] = useState(false);
  const [dateError, setDateError] = useState("");
  const [openFilteredModal, setOpenFilteredModal] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  // Filtrar por búsqueda (searchTerm)
  useEffect(() => {
    const results = users.filter((user) =>
      user.nombre.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredUsers(results);
    setPage(1); // Resetear la página a la primera al realizar una búsqueda
  }, [searchTerm, users]);

  // Filtrar por fechas (startDate y endDate)
  useEffect(() => {
    if (startDate && endDate) {
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);

      const results = users.filter((user) => {
        const userDate = new Date(user.createdAt);
        return userDate >= startDateObj && userDate <= endDateObj;
      });

      setFilteredUsersDate(results);
      setPage(1); // Resetear la página a la primera al realizar un cambio en las fechas
    }
  }, [startDate, endDate, users]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // Cambiar la variable que se está paginando
  const paginatedUsers = filteredUsers.slice(
    (page - 1) * itemsPerPage,
    page * itemsPerPage
  );

  const fetchUsers = async () => {
    try {
      const usersCollection = collection(db, "users");
      const usersSnapshot = await getDocs(usersCollection);
      const usersData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toLocaleDateString(),
        status: doc.data().status || 0,
      }));
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleDeleteClick = (userId) => {
    setUserToDelete(userId);
    setOpenConfirmModal(true);
  };

  const confirmDelete = async () => {
    try {
      const productsQuery = query(
        collection(db, "productos"),
        where("userId", "==", userToDelete)
      );
      const productsSnapshot = await getDocs(productsQuery);

      const deleteProductsPromises = productsSnapshot.docs.map((productDoc) =>
        deleteDoc(doc(db, "productos", productDoc.id))
      );
      await Promise.all(deleteProductsPromises);

      await deleteDoc(doc(db, "users", userToDelete));

      setUsers(users.filter((user) => user.id !== userToDelete));

      toast.success("Usuario y productos eliminados con éxito!");
    } catch (error) {
      console.error("Error eliminando usuario o productos:", error);
      toast.error("Error eliminando el usuario o sus productos.");
    }

    setOpenConfirmModal(false);
  };

  const handleStatusChange = async (userId, currentStatus) => {
    try {
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { status: currentStatus === 0 ? 2 : 0 });

      if (currentStatus === 0) {
        const productsQuery = query(
          collection(db, "productos"),
          where("userId", "==", userId),
          where("status", "==", 1)
        );
        const productsSnapshot = await getDocs(productsQuery);
        const productUpdates = productsSnapshot.docs.map((doc) => {
          return updateDoc(doc.ref, { status: 2 });
        });
        await Promise.all(productUpdates);
      }

      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user.id === userId
            ? { ...user, status: currentStatus === 0 ? 2 : 0 }
            : user
        )
      );
      toast.success("Status actualizado con éxito!");
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  const handleOpenModal = (userId) => {
    setSelectedUserId(userId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedUserId(null);
  };

  const handleOpenModalEdit = (userId) => {
    setSelectedUserId(userId);
    setOpenModalEdit(true);
  };

  const handleCloseModalEdit = () => {
    setSelectedUserId(null);
    setOpenModalEdit(false);
  };

  // Esta función abre el modal de fechas
  const handleOpenDateModal = () => setOpenDateModal(true);

  const handleApplyDateFilter = async () => {
    // valida fecha de inputs
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
      console.error("Error: Invalid date format");
      return;
    }

    // Fetch products desde Firestore
    try {
      const productsCollection = collection(db, "users");
      const productsSnapshot = await getDocs(productsCollection);

      const filteredProductsArray = productsSnapshot.docs
        .map((doc) => {
          const data = doc.data();
          const createdAt =
            data.createdAt instanceof Timestamp
              ? new Date(data.createdAt.seconds * 1000)
              : new Date(data.createdAt);

          // Verificar si la fecha de creación está dentro del rango
          if (createdAt >= startDateObj && createdAt <= endDateObj) {
            return {
              id: doc.id,
              nombre: data.nombre,
              userId: data.userId,
              createdAt: createdAt.toISOString().split("T")[0],
              email: data.email,
            };
          } else {
            return null; // Filtrar los usuarios fuera del rango
          }
        })
        .filter(Boolean); // Eliminar valores nulos

      // Ordenar por userId
      filteredProductsArray.sort((a, b) =>
        a.userId < b.userId ? -1 : a.userId > b.userId ? 1 : 0
      );

      setFilteredUsersDate(filteredProductsArray); // Actualiza el estado solo para el modal
      setOpenFilteredModal(true); // Abre el modal con los usuarios filtrados
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const handleCloseFilteredModal = () => {
    setOpenFilteredModal(false);
    setFilteredUsersDate([]);
  };
  const handleDateChange = (field, value) => {
    const selectedDate = new Date(value);

    if (field === "start") {
      setStartDate(selectedDate);

      if (endDate && selectedDate > endDate) {
        setDateError(
          "La fecha de inicio no puede ser mayor que la fecha de fin."
        );
      } else {
        setDateError(""); // Elimina el mensaje de error si la comparación es válida
      }
    } else {
      setEndDate(selectedDate);

      if (startDate && selectedDate < startDate) {
        setDateError(
          "La fecha de fin no puede ser menor que la fecha de inicio."
        );
      } else {
        setDateError(""); // Elimina el mensaje de error si la comparación es válida
      }
    }
  };

  // Esta función cierra el modal de fechas
  const handleCloseDateModal = () => setOpenDateModal(false);

  return (
    <div style={{ padding: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Usuarios</h2>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleOpenDateModal}
          style={{ textTransform: "capitalize" }}
          startIcon={<CalendarMonthRoundedIcon />}
        >
          filtrar por fechas
        </Button>
      </div>

      {/* Modal de selección de fechas */}
      <Modal
        open={openDateModal}
        onClose={handleCloseDateModal}
        aria-labelledby="modal-fechas"
        aria-describedby="modal-seleccionar-fechas"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <h3>Seleccionar Fechas</h3>
          <TextField
            label="Fecha de Inicio"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={startDate ? startDate.toISOString().split("T")[0] : ""} // Asegúrate de pasar el formato YYYY-MM-DD
            onChange={(e) => handleDateChange("start", e.target.value)}
            error={Boolean(dateError)}
            helperText={dateError}
            margin="normal"
          />
          <TextField
            label="Fecha de Fin"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={endDate ? endDate.toISOString().split("T")[0] : ""} // Asegúrate de pasar el formato YYYY-MM-DD
            onChange={(e) => handleDateChange("end", e.target.value)}
            error={Boolean(dateError)}
            helperText={dateError}
            margin="normal"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleApplyDateFilter}
            style={{ marginTop: "20px" }}
          >
            Aplicar Filtro
          </Button>
        </div>
      </Modal>

      {/* Modal de usuarios filtrados */}

      <Modal
        open={openFilteredModal}
        onClose={handleCloseFilteredModal}
        aria-labelledby="modal-usuarios-filtrados"
        aria-describedby="modal-lista-usuarios-filtrados"
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "8px",
            overflowY: "auto",
            maxHeight: "80%",
          }}
        >
          <div
            style={{
              fontSize: "12px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "5px 10px 5px 5px",
              background: "#ececec",
              borderRadius: "5px",
            }}
          >
            <div>
              <h3>Usuarios Filtrados</h3>
              <p>
                <strong>Cantidad de usuarios:</strong>{" "}
                {filteredUsersDate.length}
              </p>
              <p>
                <strong>Fecha de inicio:</strong>{" "}
                {startDate
                  ? startDate.toISOString().split("T")[0]
                  : "No seleccionada"}
              </p>
              <p>
                <strong>Fecha de fin:</strong>{" "}
                {endDate
                  ? endDate.toISOString().split("T")[0]
                  : "No seleccionada"}
              </p>
            </div>

            {filteredUsersDate.length > 0 && (
              <CSVLink
                data={filteredUsersDate.map((user) => ({
                  Nombre: user.nombre,
                  Correo: user.email,
                  Fecha: user.createdAt,
                }))}
                filename={"usuarios_filtrados.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Descargar CSV
              </CSVLink>
            )}
          </div>

          {filteredUsersDate.length === 0 ? (
            <p>No se encontraron usuarios en este rango de fechas.</p>
          ) : (
            <TableBody>
              {filteredUsersDate.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.nombre}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.createdAt}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </div>
      </Modal>

      <TextField
        label="Buscar usuarios"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        size="small"
      />

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableHeaderCell">Nombre</TableCell>
              <TableCell className="tableHeaderCell">Cédula</TableCell>
              <TableCell className="tableHeaderCell">Email</TableCell>
              <TableCell className="tableHeaderCell">Teléfono</TableCell>
              <TableCell className="tableHeaderCell">
                Fecha de Creación
              </TableCell>
              <TableCell className="tableHeaderCell" colSpan={4}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedUsers.map((user) => (
              <TableRow key={user.id}>
                <TableCell component="th" scope="row">
                  {user.nombre}
                </TableCell>
                <TableCell>{user.cedula}</TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.telefono}</TableCell>
                <TableCell>{user.createdAt}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleOpenModal(user.id)}
                    color="primary"
                  >
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => handleOpenModalEdit(user.id)}
                    color="primary"
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleDeleteClick(user.id)}>
                    <DeleteIcon color="warning" />
                  </IconButton>
                </TableCell>
                <TableCell>
                  <Switch
                    checked={user.status === 0 || user.status === undefined}
                    onChange={() => handleStatusChange(user.id, user.status)}
                  />
                  {user.status === 2 ? " Inactivado" : " Activo"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        count={Math.ceil(filteredUsers.length / itemsPerPage)}
        page={page}
        onChange={handlePageChange}
        style={{ marginTop: 20, display: "flex", justifyContent: "center" }}
      />

      {/* Modal de confirmación de eliminación */}
      <Modal
        open={openConfirmModal}
        onClose={() => setOpenConfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div
          style={{
            padding: 20,
            backgroundColor: "white",
            margin: "auto",
            maxWidth: 400,
            display: "flex",
            flexDirection: "column",
            gap: 15,
            marginTop: 20,
            borderRadius: 10,
          }}
        >
          <h2>Confirmar eliminación</h2>
          <p>¿Estás seguro de que deseas eliminar este usuario?</p>
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{ flex: 1 }}
              variant="contained"
              color="primary"
              onClick={confirmDelete}
            >
              Confirmar
            </Button>
            <Button
              style={{ flex: 1 }}
              variant="outlined"
              color="secondary"
              onClick={() => setOpenConfirmModal(false)}
            >
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal para ver detalles del usuario */}

      {selectedUserId && (
        <User
          userId={selectedUserId}
          openModal={openModal}
          handleCloseModal={handleCloseModal}
        />
      )}

      {/* Modal para editar el usuario */}
      <Modal open={openModalEdit} onClose={handleCloseModalEdit}>
        <div style={modalStyles}>
          {selectedUserId && (
            <EditUserModal
              fetchUsers={fetchUsers}
              userId={selectedUserId}
              close={handleCloseModalEdit}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}

export default UsersScreen;

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "white",
  padding: "10px",
  borderRadius: "8px",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  display: "flex",
  flexDirection: "column",
  gap: 5,
};
