import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db } from "../../firebase";
import { updateDoc, doc, getDocs, collection } from "firebase/firestore";
import { toast } from "react-toastify";
import { useMediaQuery } from "@mui/material";
import { format } from "date-fns";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxHeight: "80vh",
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  borderRadius: 2,
  p: 4,
  overflowY: "auto",
};

const BannerDetailModal = ({ open, handleClose, anuncio, fetchAnuncios }) => {
  const [loading, setLoading] = useState(false);
  const formattedDate = format(
    anuncio.createdAt.toDate(),
    "dd MMM yyyy HH:mm:ss"
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <h2 id="modal-modal-title">Anuncio</h2>
        {anuncio ? (
          <>
            <h3>Se busca: {anuncio.title}</h3>
            <br />
            <h4>Descripción</h4>
            <p>{anuncio.description}</p>
            <br />
            <p>
              <b>Provincia: </b>
              {anuncio.province}
            </p>
            <p>
              <b>Ciudad: </b>
              {anuncio.city}
            </p>
            <p>
              <b>Fecha de publicación:</b> {formattedDate}
            </p>
          </>
        ) : (
          <p>No hay datos disponibles</p>
        )}
      </Box>
    </Modal>
  );
};

export default BannerDetailModal;
