import React, { useState, useEffect } from "react";
import "../styles/styles.css";
import { db } from "../firebase";
import {
  getDocs,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { deleteObject, ref, getStorage } from "firebase/storage";
import { toast } from "react-toastify";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddBannerModal from "../components/bannerPublicidad/AddBannerModal";
import BannerDetailModal from "../components/bannerPublicidad/BannerDetailModal";

export default function BannersPublicidadScreen() {
  const [openAddBanner, setOpenAddBanner] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [categories, setCategories] = useState([]);
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "categorias"));
        const categoryList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoryList);
      } catch (error) {
        console.error("Error fetching categories: ", error);
      }
    };

    fetchCategories();
  }, []);

  const fetchBanners = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "banners"));
      const bannerList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBanners(bannerList);
    } catch (error) {
      console.error("Error fetching banners: ", error);
    }
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const handleOpenAddBanner = () => setOpenAddBanner(true);
  const handleCloseAddBanner = () => setOpenAddBanner(false);

  const handleOpenDetail = (banner) => {
    setSelectedBanner(banner);
    setOpenDetail(true);
  };
  const handleCloseDetail = () => {
    setSelectedBanner(null);
    setOpenDetail(false);
  };

  const handleDelete = async (id, imageUrl) => {
    const isConfirmed = window.confirm(
      "¿Estás seguro que deseas eliminar el banner?"
    );

    if (!isConfirmed) return; // termina si el usuario cancela la eliminacion

    try {
      const storage = getStorage();

      // elimina la imagen desde Firebase Storage si es que existe
      if (imageUrl) {
        const imageRef = ref(storage, imageUrl);
        await deleteObject(imageRef);
      }

      // elimina el banner en el firestore
      await deleteDoc(doc(db, "banners", id));

      // recarga la lista de banners
      fetchBanners();

      toast.success("Banner eliminado con éxito!");
    } catch (error) {
      console.error("Error al eliminar el banner: ", error);
      toast.error("Error al eliminar el banner!");
    }
  };

  const handleStatusChange = async (id, currentStatus) => {
    const newStatus = currentStatus === 1 ? 0 : 1;
    try {
      await updateDoc(doc(db, "banners", id), { status: newStatus });
      fetchBanners();
      toast.success(
        `Estado actualizado a ${newStatus === 1 ? "Activo" : "Inactivo"}!`
      );
    } catch (error) {
      console.error("Error updating status: ", error);
      toast.error("Error updating status!");
    }
  };

  return (
    <>
      <h2 className="titlePageInt">Banners Publicidad</h2>

      <div
        style={{
          display: "flex",
          padding: 20,
          gap: 10,
          flexDirection: "column",
        }}
      >
        <div>
          <Button
            style={{ fontSize: 12 }}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleOpenAddBanner}
          >
            crear nuevo banner
          </Button>
        </div>

        <TableContainer component={Paper} style={{ marginTop: 20 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="tableHeaderCell">
                  <b>Titulo</b>
                </TableCell>
                <TableCell className="tableHeaderCell">
                  <b>Link Type</b>
                </TableCell>
                <TableCell className="tableHeaderCell">
                  <b>Prioridad</b>
                </TableCell>
                <TableCell className="tableHeaderCell">
                  <b>Status</b>
                </TableCell>
                <TableCell className="tableHeaderCell" colSpan={2}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {banners.map((banner) => (
                <TableRow key={banner.id}>
                  <TableCell>{banner.title}</TableCell>
                  <TableCell>{banner.linkType}</TableCell>
                  <TableCell>{banner.priority}</TableCell>

                  <TableCell>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={banner.status === 1}
                          onChange={() =>
                            handleStatusChange(banner.id, banner.status)
                          }
                        />
                      }
                      label={banner.status === 1 ? "Activo" : "Inactivo"}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="view"
                      color="primary"
                      onClick={() => handleOpenDetail(banner)}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="delete"
                      color="secondary"
                      onClick={() => handleDelete(banner.id, banner.imageUrl)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddBannerModal
          open={openAddBanner}
          handleClose={handleCloseAddBanner}
          fetchBanners={fetchBanners}
          categories={categories}
        />
        {selectedBanner && (
          <BannerDetailModal
            open={openDetail}
            handleClose={handleCloseDetail}
            banner={selectedBanner}
            fetchBanners={fetchBanners}
          />
        )}
      </div>
    </>
  );
}
