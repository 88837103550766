import React, { useState, useEffect } from "react";
import { Line, Bar } from "react-chartjs-2";
import InventoryIcon from "@mui/icons-material/Inventory";
import PeopleIcon from "@mui/icons-material/People";

import { db } from "../firebase";
import { collection, query, getDocs } from "firebase/firestore";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { AspectRatio, Height } from "@mui/icons-material";
import { height, width } from "@mui/system";

// Registrar los componentes necesarios de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

// Función para obtener los datos de usuarios por mes
const fetchUserDataByMonth = async (year, setTotalUsers) => {
  try {
    const usersRef = collection(db, "users");
    const q = query(usersRef);
    const querySnapshot = await getDocs(q);

    const monthlyData = Array(12).fill(0);
    let totalUsers = 0;

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const createdAt = userData.createdAt?.toDate(); // Asegurar que es un Timestamp

      if (createdAt && createdAt.getFullYear() === year) {
        const month = createdAt.getMonth();
        monthlyData[month] += 1;
        totalUsers += 1;
      }
    });

    setTotalUsers(totalUsers);
    return monthlyData;
  } catch (error) {
    console.error("Error al obtener los datos de usuarios:", error);
    setTotalUsers(0);
    return Array(12).fill(0);
  }
};

// Función para obtener los datos de productos por mes
const fetchProductDataByMonth = async (year, setTotalProducts) => {
  try {
    const productsRef = collection(db, "productos");
    const q = query(productsRef);
    const querySnapshot = await getDocs(q);

    const monthlyData = Array(12).fill(0);
    let totalProducts = 0;

    querySnapshot.forEach((doc) => {
      const productData = doc.data();
      const createAt = productData.createAt?.toDate(); // Asegurar que es un Timestamp

      if (createAt && createAt.getFullYear() === year) {
        const month = createAt.getMonth();
        monthlyData[month] += 1;
        totalProducts += 1;
      }
    });

    setTotalProducts(totalProducts);
    return monthlyData;
  } catch (error) {
    console.error("Error al obtener los datos de productos:", error);
    setTotalProducts(0);
    return Array(12).fill(0);
  }
};

// Función para obtener los datos de usuarios por día
const fetchUserDataByDay = async (date, setTotalUsersForDay) => {
  try {
    const usersRef = collection(db, "users");
    const q = query(usersRef);
    const querySnapshot = await getDocs(q);

    let totalUsers = 0;

    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      const createdAt = userData.createdAt?.toDate(); // Asegurar que es un Timestamp

      if (createdAt && createdAt.toDateString() === date.toDateString()) {
        totalUsers += 1;
      }
    });

    setTotalUsersForDay(totalUsers);
    return totalUsers;
  } catch (error) {
    console.error("Error al obtener los datos de usuarios:", error);
    setTotalUsersForDay(0);
    return 0;
  }
};

// Función para obtener los datos de productos por día
const fetchProductDataByDay = async (date, setTotalProductsForDay) => {
  try {
    const productsRef = collection(db, "productos");
    const q = query(productsRef);
    const querySnapshot = await getDocs(q);

    let totalProducts = 0;

    querySnapshot.forEach((doc) => {
      const productData = doc.data();
      const createAt = productData.createAt?.toDate(); // Asegurar que es un Timestamp

      if (createAt && createAt.toDateString() === date.toDateString()) {
        totalProducts += 1;
      }
    });

    setTotalProductsForDay(totalProducts);
    return totalProducts;
  } catch (error) {
    console.error("Error al obtener los datos de productos:", error);
    setTotalProductsForDay(0);
    return 0;
  }
};

const Home = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [userData, setUserData] = useState(null);
  const [productData, setProductData] = useState(null);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalUsersForDay, setTotalUsersForDay] = useState(0);
  const [totalProductsForDay, setTotalProductsForDay] = useState(0);
  const [selectedDate, setSelectedDate] = useState("");
  const [totalUsersGlobal, setTotalUsersGlobal] = useState(0);
  const [totalProductsGlobal, setTotalProductsGlobal] = useState(0);
  const [totalChatsGlobal, setTotalChatsGlobal] = useState(0);
  const [totalVentasGlobal, setTotalVentasGlobal] = useState(0);

  const [totalMessagesGlobal, setTotalMessagesGlobal] = useState(0);

  const [view, setView] = useState("mes"); // "mes" | "dia" | "semana"

  useEffect(() => {
    if (view === "mes") {
      const loadUserData = async () => {
        const userMonthlyData = await fetchUserDataByMonth(year, setTotalUsers);

        const monthLabels = [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ];

        const labelsWithValues = monthLabels.map(
          (month, index) => `${month} (${userMonthlyData[index]})`
        );

        setUserData({
          labels: labelsWithValues,
          datasets: [
            {
              label: "Usuarios",
              data: userMonthlyData,
              borderColor: "rgba(75,192,192,1)",
              backgroundColor: "rgba(75,192,192,0.2)",
              fill: true,
            },
          ],
        });
      };

      const loadProductData = async () => {
        const productMonthlyData = await fetchProductDataByMonth(
          year,
          setTotalProducts
        );

        const monthLabels = [
          "Ene",
          "Feb",
          "Mar",
          "Abr",
          "May",
          "Jun",
          "Jul",
          "Ago",
          "Sep",
          "Oct",
          "Nov",
          "Dic",
        ];

        const labelsWithValues = monthLabels.map(
          (month, index) => `${month} (${productMonthlyData[index]})`
        );

        setProductData({
          labels: labelsWithValues,
          datasets: [
            {
              label: "Productos",
              data: productMonthlyData,
              borderColor: "rgba(153,102,255,1)",
              backgroundColor: "rgba(153,102,255,0.2)",
              fill: true,
            },
          ],
        });
      };

      loadUserData();
      loadProductData();
    }
  }, [year, view]);

  useEffect(() => {
    if (view === "dia" && selectedDate) {
      const date = new Date(selectedDate);

      const loadUserDataForDay = async () => {
        const totalUsers = await fetchUserDataByDay(date, setTotalUsersForDay);
      };

      const loadProductDataForDay = async () => {
        const totalProducts = await fetchProductDataByDay(
          date,
          setTotalProductsForDay
        );
      };

      loadUserDataForDay();
      loadProductDataForDay();
    }
  }, [selectedDate, view]);

  const fetchUserTotalGlobal = async () => {
    try {
      const usersRef = collection(db, "users");
      const querySnapshot = await getDocs(usersRef);

      const totalUsers = querySnapshot.size; // Obtiene el número total de documentos

      setTotalUsersGlobal(totalUsers); // Guarda el total en el estado
      return totalUsers; // Retorna el total de usuarios
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
      setTotalUsersGlobal(0);
      return 0;
    }
  };

  const fetchProductsTotalGlobal = async () => {
    try {
      const usersRef = collection(db, "productos");
      const querySnapshot = await getDocs(usersRef);

      const totalUsers = querySnapshot.size; // Obtiene el número total de documentos

      setTotalProductsGlobal(totalUsers); // Guarda el total en el estado
      return totalUsers; // Retorna el total de usuarios
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
      setTotalProductsGlobal(0);
      return 0;
    }
  };

  const fetchVentasTotalGlobal = async () => {
    try {
      const usersRef = collection(db, "ventas");
      const querySnapshot = await getDocs(usersRef);

      const totalUsers = querySnapshot.size; // Obtiene el número total de documentos

      setTotalVentasGlobal(totalUsers); // Guarda el total en el estado
      return totalUsers; // Retorna el total de usuarios
    } catch (error) {
      console.error("Error al obtener los datos de usuarios:", error);
      setTotalVentasGlobal(0);
      return 0;
    }
  };

  const fetchChatsTotalGlobal = async () => {
    try {
      const chatsRef = collection(db, "chats");
      const chatsSnapshot = await getDocs(chatsRef);

      let totalChats = chatsSnapshot.size; // Total de chats
      let totalMessages = 0;

      // Recorremos cada chat para contar sus mensajes
      const messagesPromises = chatsSnapshot.docs.map(async (chatDoc) => {
        const messagesRef = collection(chatDoc.ref, "mensajes");
        const messagesSnapshot = await getDocs(messagesRef);
        totalMessages += messagesSnapshot.size;
      });

      // Esperamos a que todas las promesas se resuelvan
      await Promise.all(messagesPromises);

      // Guardamos en el estado
      setTotalChatsGlobal(totalChats);
      setTotalMessagesGlobal(totalMessages);

      return { totalChats, totalMessages }; // Retornamos ambos valores
    } catch (error) {
      console.error("Error al obtener los datos de chats:", error);
      setTotalChatsGlobal(0);
      setTotalMessagesGlobal(0);
      return { totalChats: 0, totalMessages: 0 };
    }
  };

  fetchVentasTotalGlobal();
  fetchChatsTotalGlobal();
  fetchUserTotalGlobal();
  fetchProductsTotalGlobal();

  const buttonStyle = {
    backgroundColor: "rgb(25, 118, 210)", // Color de fondo
    padding: "5px 20px", // Espaciado dentro del botón
    border: "none", // Sin borde
    color: "#fff", // Color del texto
    cursor: "pointer", // Cambia el cursor al pasar por encima
    borderRadius: "3px", // Bordes redondeados
  };

  const buttonBackStyle = {
    backgroundColor: "#333", // Color de fondo
    padding: "5px 20px", // Espaciado dentro del botón
    border: "none", // Sin borde
    color: "#fff", // Color del texto
    cursor: "pointer", // Cambia el cursor al pasar por encima
    borderRadius: "3px", // Bordes redondeados
  };

  const content_inputs = {
    display: "flex",
    alignItems: "center",
    gap: 10,
    marginBottom: 20,
  };
  const item_stadistics_container = {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginBottom: 20,
  };
  const item_stadistics = {
    padding: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "aquamarine",
    borderRadius: "50%",
    borderWidth: 5,
    borderColor: "black",
    borderStyle: "solid",
    justifyContent: "center",
    height: 90,
    width: 90,
  };
  return (
    <div style={{ padding: 20 }}>
      <div style={item_stadistics_container}>
        <div style={item_stadistics}>
          <h1>{totalUsersGlobal}</h1>
          <h5>Usuarios</h5>
        </div>

        <div style={{ ...item_stadistics, background: "chartreuse" }}>
          <h1>{totalProductsGlobal}</h1>
          <h5>Productos</h5>
        </div>

        <div style={{ ...item_stadistics, background: "deeppink" }}>
          <h1>{totalChatsGlobal}</h1>
          <h5>Chats</h5>
        </div>

        <div style={{ ...item_stadistics, background: "deepskyblue" }}>
          <h1>{totalMessagesGlobal}</h1>
          <h5>Mensajes</h5>
        </div>

        <div style={{ ...item_stadistics, background: "darkorange" }}>
          <h1>{totalVentasGlobal}</h1>
          <h5>Ventas</h5>
        </div>
      </div>
      <div style={content_inputs}>
        {view !== "dia" && (
          <>
            Ver por año:
            <select
              value={year}
              onChange={(e) => setYear(parseInt(e.target.value))}
              style={{
                padding: "5px",
                border: "none",
                borderBottom: "1px solid #000",
                outline: "none",
              }}
            >
              <option value={2024}>2024</option>
              <option value={2025}>2025</option>
            </select>
          </>
        )}
        {view === "dia" && (
          <button
            style={view !== "dia" ? buttonStyle : buttonBackStyle}
            onClick={() => setView("mes")}
          >
            {view !== "dia" ? "Mes" : "Regresar"}
          </button>
        )}{" "}
        |
        <button style={buttonStyle} onClick={() => setView("dia")}>
          Ver por día
        </button>
      </div>
      {view !== "dia" && <h3>Año - {year}</h3>}
      {view === "dia" && (
        <div style={{ marginBottom: "20px", marginTop: "20px" }}>
          <label>Selecciona un día:</label>
          <input
            type="date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ padding: "5px", marginLeft: "10px" }}
          />
        </div>
      )}

      {view === "dia" && selectedDate && (
        <div
          style={{
            backgroundColor: "#f0f4f8", // Color de fondo suave
            padding: "20px", // Espaciado alrededor del contenido
            borderRadius: "8px", // Bordes redondeados
            boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Sombra sutil para darle profundidad
            maxWidth: "400px", // Máxima anchura
            border: "2px solid #CCC", // Borde delgada
          }}
        >
          <h5
            style={{
              color: "#333", // Color del texto
              fontWeight: "bold", // Negrita en el título
              marginBottom: "15px", // Espaciado debajo del título
            }}
          >
            Datos para el {selectedDate}:
          </h5>
          <p
            style={{
              color: "#555", // Color del texto
              fontWeight: "500", // Peso de la fuente un poco más fuerte
            }}
          >
            Total de Usuarios:{" "}
            <span style={{ fontWeight: "700" }}>{totalUsersForDay}</span>
          </p>
          <p
            style={{
              color: "#555", // Color del texto
              fontWeight: "500", // Peso de la fuente un poco más fuerte
            }}
          >
            Total de Productos:{" "}
            <span style={{ fontWeight: "700" }}>{totalProductsForDay}</span>
          </p>
        </div>
      )}

      {view === "mes" && (
        <div>
          <p>
            Total de Usuarios: <b>{totalUsers}</b>
          </p>
          <p>
            Total de Productos: <b>{totalProducts}</b>
          </p>

          <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
            <div
              style={{
                flex: "1 1 300px",
                minWidth: "300px",
                maxWidth: "500px",
              }}
            >
              <h5
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <PeopleIcon htmlColor="#555" /> Cantidad de Usuarios Total y por
                Mes
              </h5>
              {userData ? <Line data={userData} /> : <p>Cargando datos...</p>}
            </div>

            <div
              style={{
                flex: "1 1 300px",
                minWidth: "300px",
                maxWidth: "500px",
              }}
            >
              <h5
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
              >
                <InventoryIcon htmlColor="#555" /> Cantidad de Productos
                Agregados por Mes
              </h5>
              {productData ? (
                <Bar data={productData} />
              ) : (
                <p>Cargando datos...</p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
